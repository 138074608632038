export const extendContainer = {
    container: () => ({
        gridRowGap: '2.7rem',
        tabletLarge: {
            gridRowGap: '0',
            gridColumnGap: '2.7rem',
            gridTemplateColumns: 'unset',
        },
    }),
};
