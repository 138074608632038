import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

const { DynamicContainer, TitleTypes, Title, Text } = UI;

const WhyColumn = ({ rules, title, description, isLast }) => (
    <DynamicContainer isLast={isLast} extend={rules.extendContainer} columns={4}>
        <Title extend={rules.extendTitle} type={TitleTypes.H4}>
            <FormattedMessage id={title} />
        </Title>
        <Text as="p">
            <FormattedMessage id={description} />
        </Text>
    </DynamicContainer>
);

WhyColumn.propTypes = {
    rules: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
};

export default WhyColumn;
