import React from 'react';

import { Layout } from '../../modules/ui';
import ContactPrague from '../../modules/contact/ContactPrague';
import Seo from '../../components/SEO';
import * as Constants from '../../constants';

const PraguePage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.PRAGUE} />
            <Layout footerRef={footerRef}>
                <ContactPrague footerRef={footerRef} />
            </Layout>
        </>
    );
};

export default PraguePage;
