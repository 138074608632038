import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import { content } from './content';

import WhyColumn from './WhyColumn';

const { Text, TextTypes, DynamicContainer, ContainerType } = UI;

const WhySection = ({ rules }) => (
    <DynamicContainer as={ContainerType.SECTION} isFluid extend={rules.extendContainer} type={ContainerType.GRID}>
        <DynamicContainer columns={12}>
            <Text as="h2" type={TextTypes.SECTION}>
                <FormattedMessage id={content.title} />
            </Text>
        </DynamicContainer>
        {content.columns.map((item, index) => (
            <WhyColumn
                key={item.id}
                isLast={index === content.columns.length - 1}
                title={item.title}
                description={item.description}
            />
        ))}
    </DynamicContainer>
);

WhySection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default WhySection;
