import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Office } from 'modules/offices';
import { InquirySection } from '../../inquiry';
import HeroSection from '../../hero-section';

import * as UI from '../../ui';

import Address from '../components/Address';
import OfficeGallery from '../components/OfficeGallery';
import WhySection from '../components/WhySection';
import MapSection from '../components/MapSection';

import HeroImage from '../components/HeroImage';

const { SectionNavigation, sectionTitles, TitleTypes } = UI;

const ContactPrague = ({ footerRef }) => {
    const office = Office.PRAGUE;

    const heroRef = React.useRef();
    const galleryRef = React.useRef();
    const contactRef = React.useRef();

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.general,
            id: 1,
        },
        gallery: {
            ref: galleryRef,
            title: sectionTitles.gallery.prague,
            id: 2,
        },
        contact: {
            ref: contactRef,
            title: sectionTitles.offices.prague,
            id: 4,
        },
        footer: {
            ref: footerRef,
            title: sectionTitles.footer,
            id: 5,
        },
    };

    return (
        <>
            <HeroSection
                title={<FormattedMessage id="contact.prague.hero.title" />}
                titleAs={TitleTypes.H1}
                sectionTitle={<FormattedMessage id="contact.prague.section.title" />}
                description={<FormattedMessage id="contact.prague.hero.description" />}
                address={<Address contactLinkEventId="contact-top" office={office} />}
                ref={heroRef}
                customContent={<HeroImage src="contacts/heroes/prague.png" alt="contact.prague.hero.image.alt" />}
            />
            <OfficeGallery reference={galleryRef} office={office} />
            <WhySection />
            <MapSection office={office} />
            <InquirySection ref={contactRef} />
            <SectionNavigation sections={sections} />
        </>
    );
};

ContactPrague.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    footerRef: PropTypes.shape().isRequired,
};

export default ContactPrague;
