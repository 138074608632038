export const extendContainer = {
    container: ({ isLast, theme: { colors } }) => ({
        paddingRight: !isLast ? '2.7rem' : 0,

        tabletLarge: {
            borderRight: !isLast ? `1px solid ${colors.lightGrey}` : 'none',
        },
    }),
};

export const extendTitle = {
    title: () => ({
        marginTop: '0.6rem',
        marginBottom: '1.8rem',
        tablet: {
            fontSize: '2.5rem',
        },
    }),
};
