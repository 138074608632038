export const content = {
    title: 'contact.prague.why.title',
    columns: [
        {
            id: 1,
            title: 'contact.prague.why.column1.title',
            description: 'contact.prague.why.column1.description',
        },
        {
            id: 2,
            title: 'contact.prague.why.column2.title',
            description: 'contact.prague.why.column2.description',
        },
        {
            id: 3,
            title: 'contact.prague.why.column3.title',
            description: 'contact.prague.why.column3.description',
        },
    ],
};
